import React from "react"
import "./hero-paralax.styles.scss"

import SpinningLogos from "../spinning-logos/spinning-logos.component"

const HeroParalax = () => (
  <div className="background-container">
    <div className="background">
      <div className="content">
        <h1>Hi, I'm David Ernest</h1>
        <p>
          I am a full-stack developer solving interesting problems using modern
          technology.
        </p>
        <a
          className="badge-link"
          target="_blank"
          rel="noopener"
          href="/DavidErnestWebsiteResume.pdf"
        >
          Resume
        </a>
        <a
          className="badge-link"
          target="_blank"
          rel="noopener"
          href="https://github.com/dernest92"
        >
          GitHub
        </a>
      </div>
      <SpinningLogos />
    </div>
  </div>
)

export default HeroParalax
