import React from "react"
// import './project.styles.scss'

const useOnScreen = options => {
  const [ref, setRef] = React.useState(null)
  const [visible, setVisible] = React.useState(false)

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options)

    if (ref) {
      observer.observe(ref)
    }
    return () => {
      if (ref) {
        observer.unobserve(ref)
      }
    }
  }, [ref, options])
  return [setRef, visible]
}

export default ({ children, noMobile }) => {
  const [setRef, visible] = useOnScreen({ rootMargin: "-40%" })

  return (
    <div
      className={`screenshots${noMobile ? " no-mobile" : ""}${
        visible ? " visible" : ""
      }`}
      ref={setRef}
    >
      {children}
    </div>
  )
}
