import React from "react"
import "./laptop.styles.scss"

const Laptop = ({ children }) => {
  return (
    <div className="laptop">
      <div className="laptop-screen">
        <div className="laptop-content">{children}</div>
      </div>
      <div className="laptop-base">
        <div className="laptop-trackpad"></div>
      </div>
    </div>
  )
}

export default Laptop
