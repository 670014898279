import React from 'react'
import ReactLogo from '../../images/React-icon.svg'
import NodeLogo from '../../images/Node.js_logo.svg'
import VueLogo from '../../images/Vue.js_Logo_2.svg'
import WebpackLogo from '../../images/webpack.svg'
import GitLogo from '../../images/git-icon.png'
import JsLogo from '../../images/javascript-logo.svg'
import './spinning-logos.styles.scss'

const SpinningLogos = () => {

    return (
        <div className="spinning-container">
            <div className="circle-container">
                <div>
                    <img className="spinning-logo" src={ReactLogo} alt="react logo"/>
                </div>
                <div>
                    <img className="still-logo" src={NodeLogo} alt="Node logo"/>
                </div>
                <div>
                    <img className="spinning-logo" src={WebpackLogo} alt="webpack logo"/>
                </div>
                <div>
                    <img className="still-logo" src={VueLogo} alt="Vue logo"/>
                </div>
                <div>
                    <img className="spinning-logo" src={GitLogo} alt="git logo"/>
                </div>
                <div>
                    <img className="still-logo" src={JsLogo} alt="Javascript logo"/>
                </div>
            </div>
        </div>
    )
}

// Webpack
// Git
// Mongo

export default SpinningLogos