import React from "react"
import "./phone.styles.scss"

const Phone = ({ children }) => {
  return (
    <div className="iphone">
      <div className="screen-content">
        <div className="top-bar">
          <div className="url-bar">davidernest.io</div>
        </div>
        <div className="screen">{children}</div>
        <div className="bottom-bar"></div>
      </div>
    </div>
  )
}

export default Phone
