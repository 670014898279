import React from "react"
import "./project.styles.scss"

export default ({ children, reverse, noMobile }) => {
  return (
    <div
      className={`project${reverse ? " reverse" : ""}${
        noMobile ? " no-mobile" : ""
      }`}
    >
      <div className="container">{children}</div>
    </div>
  )
}
