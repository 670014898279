import React from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact/contact.component"
import HeroParalax from "../components/hero-paralax/hero-paralax.component"
import ProjectScreenshots from "../components/poject-screenshots/project-screenshots.component"
import ProjectCard from "../components/project-card/project-card.component"
import "../styles/main.scss"
import Laptop from "../components/laptop/laptop.component"
import Phone from "../components/phone/phone.component"
import DrawnTogetherPhone from "../components/image-components/drawn-together-phone"
import DrawnTogetherLaptop from "../components/image-components/drawn-together-laptop"
import BistrotekLaptop from "../components/image-components/bistrotek-laptop"
import BistrotekPhone from "../components/image-components/bistrotek-phone"
import CalendarLaptop from "../components/image-components/calendar-laptop"
import CalendarPhone from "../components/image-components/calendar-phone"
import Memoji from "../images/davememoji.png"
import VideoPlayer from "../components/video-player/video-player"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="David Ernest" />

      <HeroParalax />

      <section className="content">
        <div className="container">
          <br />
          <br />
          <h2>About Me</h2>
          <div className="split-content">
            <div>
              <p>
                I am a Full Stack Developer living in Austin, Texas. I build
                useful, interesting, and fun web applications using a modern
                tech stack.
              </p>
              <p>
                I am a huge Javascript fanatic. I love creating front end apps
                using frameworks such as Vue, React and Gatsby. For backend
                APIs, I typically use Node, Express and MongoDB, but I am always
                learning new technologies.
              </p>
              <p>I have always been driven to learn and create.</p>
              <p>
                I studied mechanical engineering at the University of
                Massachusetts, and spent several years designing and building
                industrial equipment. I eventually taught myself how to code and
                realized that I enjoyed designing software more than physical
                products.
              </p>
              <p>
                Since learning to code I have built everything from small
                websites to multi-tenant SaaS applications. You can see a few
                examples of my work below.
              </p>
              <p>
                If you are looking for a talented and motivated software
                engineer, feel free to contact me at{" "}
                <a
                  target="_blank"
                  rel="noopener nofollow"
                  href="mailto:me@davidernest.io"
                >
                  me@davidernest.io
                </a>{" "}
                or through the <a href="/#contact-form">contact form</a> below.
              </p>
            </div>
            <div>
              <img className="memoji" src={Memoji} alt="Dave Memoji" />
            </div>
          </div>
        </div>
        <br />
        <br />

        <ProjectCard>
          <ProjectScreenshots>
            <Laptop>
              <BistrotekLaptop />
            </Laptop>
            <Phone>
              <BistrotekPhone />
            </Phone>
          </ProjectScreenshots>
          <div className="content">
            <h3>Bistrobo</h3>
            <p>
              Bistrobo is a product I built for the restaurant industry. The app
              provides restaurants with a responsive online menu that customers
              can use to place orders. Restaurants also get an admin portal
              which they can use to manage their menu, orders, hours of
              operation, and more. Bistrobo also comes with automated text
              notifications and payment processing.
            </p>

            <div>
              <Link className="link" to="/bistrobo">
                Write Up + Demo
              </Link>
            </div>
          </div>
        </ProjectCard>

        <ProjectCard reverse>
          <ProjectScreenshots>
            <VideoPlayer source="https://www.youtube.com/embed/QveyFWE2zSE" />
          </ProjectScreenshots>

          <div className="content">
            <h3>Physics Engine</h3>
            <p>
              I built a physics engine in Javascript to demonstrate how the
              digits of PI can be calculated by counting the number of
              collisions between two blocks.
            </p>
            <div>
              <p>
                To learn more, check out the{" "}
                <a
                  href="https://youtu.be/QveyFWE2zSE"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  video
                </a>{" "}
                and the{" "}
                <a
                  href="https://github.com/dernest92/PhysicsVideo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  source code.
                </a>
              </p>
            </div>

            <div>
              <a
                href="https://pi-physics.netlify.com"
                target="_blank"
                className="link"
                rel="noopener noreferrer"
              >
                Live Demo
              </a>
            </div>
          </div>
        </ProjectCard>

        <ProjectCard>
          <ProjectScreenshots>
            <Laptop>
              <DrawnTogetherLaptop />
            </Laptop>
            <Phone>
              <DrawnTogetherPhone />
            </Phone>
          </ProjectScreenshots>
          <div className="content">
            <h3>Drawn Together</h3>
            <p>
              Drawn Together is a real-time multi-user drawing app. Users can
              create and join drawing boards, then doodle with their friends in
              real time. Users can adjust the size and color of their brush to
              create pocket-sized masterpieces.
            </p>
            <p>
              View code for the{" "}
              <a href="https://github.com/dernest92/vue-sketch">Vue App</a> or{" "}
              <a href="https://github.com/dernest92/sketch-api">Node Server</a>{" "}
            </p>
            <div>
              <a
                href="https://drawn-together.davidernest.io/"
                target="_blank"
                className="link"
                rel="noopener noreferrer"
              >
                Live Demo
              </a>
            </div>
          </div>
        </ProjectCard>

        <ProjectCard reverse>
          <ProjectScreenshots>
            <Laptop>
              <CalendarLaptop />
            </Laptop>
            <Phone>
              <CalendarPhone />
            </Phone>
          </ProjectScreenshots>
          <div className="content">
            <h3>Calendar App</h3>
            <p>
              This app is one of the first projects I built using Vue. I was
              tired of building simple 'to do' apps, so I decided to make a
              fully featured calendar app. Some of the more impressive features
              in this app are the fully responsive UI, the ability to drag and
              drop events from day to day, and the 'swipe gesture' interactions
              on mobile. Overall, this is an intuitive and powerful calendar,
              designed to keep all of your events in one place. Check it out!
            </p>
            <p>
              View code for the{" "}
              <a href="https://github.com/dernest92/not-another-todo-app">
                Vue App
              </a>{" "}
              or{" "}
              <a href="https://github.com/dernest92/not-another-todo-app-api">
                Node Server
              </a>{" "}
            </p>
            <div>
              <a
                href="https://calendar-app.davidernest.io/"
                className="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Live Demo
              </a>
            </div>
          </div>
        </ProjectCard>

        <br />
        <br />
        <div className="container">
          <Contact />
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
