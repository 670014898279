import React from "react"
import "./video-player.scss"

export default ({ source }) => {
  return (
    <div className="video-player">
      <iframe
        width="335"
        height="218"
        src={source}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen="true"
      ></iframe>
    </div>
  )
}
