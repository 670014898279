import React, { Component } from "react"
import CustomInput from "../custom-input/custom-input.component"
import "./contact.styles.scss"

class Contact extends Component {
  constructor() {
    super()
    this.state = {
      email: "",
      name: "",
      message: "",
    }
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  render() {
    return (
      <div className="contact-form" id="contact-form">
        <h2>Say Hello!</h2>
        <form name="contact" data-netlify="true" action="/thanks" method="POST">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <CustomInput
            name="name"
            type="text"
            required={true}
            label="Name"
            handleChange={this.handleChange}
            value={this.state.name}
          />
          <CustomInput
            name="email"
            type="email"
            required={true}
            label="Email"
            handleChange={this.handleChange}
            value={this.state.email}
          />
          <CustomInput
            name="message"
            type="textarea"
            required={true}
            label="Message"
            handleChange={this.handleChange}
            value={this.state.message}
          />

          <button className="btn full">
            <span>Submit</span>
          </button>
        </form>
      </div>
    )
  }
}

export default Contact
