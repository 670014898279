import React from "react"
import "./custom-input.styles.scss"

const CustomInput = ({
  handleChange,
  textarea,
  type = "text",
  label,
  ...otherProps
}) => {
  return (
    <div className="group">
      {type === "textarea" ? (
        <textarea
          onChange={handleChange}
          className="form-input"
          {...otherProps}
        />
      ) : (
        <input
          onChange={handleChange}
          type={type}
          className="form-input"
          {...otherProps}
        />
      )}

      {label ? (
        <label
          className={`${
            otherProps.value.length ? "shrink" : ""
          } form-input-label`}
        >
          {label}
        </label>
      ) : null}
    </div>
  )
}

export default CustomInput
